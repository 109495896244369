<template>
  <span style="width:100%;">
    <div class="group_12 flex-col lianxiwomen" v-if="w750 == 0">
      <div class="box_4 flex-row justify-between">
        <div class="group_13 flex-col align-center">
          <div class="fw2 flex-col">
          </div>
          <div class="fw flex-col">
            <img
              class="fwf"
              referrerpolicy="no-referrer"
              src="../assets/imgs/fw2.png"
            />
          </div>
        </div>
        <div class="group_14 flex-col justify-between">
          <div class="logo-red flex-row">
            <img
              class="logo-red-img"
              referrerpolicy="no-referrer"
              src="../assets/imgs/logo-red.png"
            />
          </div>
          <span class="paragraph_2"
            >
            <span v-if="lag == 'en'">Adr</span>
            <span v-else>地址</span>
            ：{{ basedata.adr }}<br />
            <span v-if="lag == 'en'">Tel</span>
            <span v-else>电话</span>
            ：{{
              basedata.tel
            }}&nbsp;&nbsp;&nbsp;&nbsp;<br />
            <span v-if="lag=='en'">Email</span>
            <span v-else>邮箱</span>
            ：{{ basedata.email }}</span
          >
        </div>
      </div>
      <div class="line_red"></div>
      <div class="box_5 flex-row justify-between">
        <span class="text_62">IPmotion.&nbsp;沪ICP备2020037860号</span>
        <!-- <img
          class="image_50"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngab0578b39c1306b573362cb4c97fa0e7926202959bb03ddffc30c5d31cc43604"
        /> -->

        <div class="social-share" data-initialized="true">
          <a href="#" class="social-share-icon icon-wechat"></a>
          <a href="#" class="social-share-icon icon-weibo"></a>
          <a href="#" class="social-share-icon icon-qq"></a>
          <a href="#" class="social-share-icon icon-twitter"></a>
          <a href="#" class="social-share-icon icon-linkedin"></a>
          <a href="#" class="social-share-icon icon-facebook"></a>
        </div>
      </div>
    </div>

    <div class="group_12 flex-col lianxiwomen" v-if="w750 == 1">
      <div class="box_4 flex-col justify-between">
        <div class="group_13 flex-col align-center">
          <div class="fw flex-col">
            <img
              class="fwf"
              referrerpolicy="no-referrer"
              src="../assets/imgs/fw2.png"
            />
          </div>
        </div>
        <div class="group_14 flex-col justify-between">
          <div class="logo-red flex-row">
            <img
              class="logo-red-img"
              referrerpolicy="no-referrer"
              src="../assets/imgs/logo-red.png"
            />
          </div>
          <span class="paragraph_2"
            >
            <span v-if="lag == 'en'">Adr</span>
            <span v-else>地址</span>
            ：{{ basedata.adr }}<br />
            <span v-if="lag == 'en'">Tel</span>
            <span v-else>电话</span>
            ：{{
              basedata.tel
            }}&nbsp;&nbsp;&nbsp;&nbsp;<br />
            <span v-if="lag=='en'">Email</span>
            <span v-else>邮箱</span>
            ：{{ basedata.email }}</span
          >

          <!-- <img
            class="image_50"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngab0578b39c1306b573362cb4c97fa0e7926202959bb03ddffc30c5d31cc43604"
          /> -->

          <div
            class="social-share"
            data-initialized="true"
            style="transform: scale(1.3); margin-left: 24px"
          >
            <a href="#" class="social-share-icon icon-wechat"></a>
            <a href="#" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-twitter"></a>
            <a href="#" class="social-share-icon icon-linkedin"></a>
            <a href="#" class="social-share-icon icon-facebook"></a>
          </div>
        </div>
      </div>
      <div class="line_red"></div>
      <div class="box_5 flex-col justify-between">
        <span class="text_62">IPmotion.&nbsp;沪ICP备2020037860号</span>
      </div>
    </div>
  </span>
</template>

<script>
// src/assets/sharejs/dist/js/social-share.min.js
import "../assets/sharejs/dist/js/social-share.min";
export default {
  name: "FooterIndex",
  props: {
    msg: String,
    w750: Number,
    basedata: {},
    lag:String,
  },
  created() {
    // console.log("sj:",sj);
    socialShare("#share-1");
  },
};
</script>

<style src="../assets/sharejs/dist/css/share.min.css" />
<style scoped>
.social-share .icon-wechat,
.social-share .icon-weibo,
.social-share .icon-qq,
.social-share .icon-twitter,
.social-share .icon-linkedin,
.social-share .icon-facebook {
  border: none;
  color: #fff;
}

/* .sharebtns {
  width: auto;
  height: auto;
} */
</style>
