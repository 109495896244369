var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"width":"100%"}},[(_vm.w750 == 0)?_c('div',{staticClass:"group_12 flex-col lianxiwomen"},[_c('div',{staticClass:"box_4 flex-row justify-between"},[_vm._m(0),_c('div',{staticClass:"group_14 flex-col justify-between"},[_vm._m(1),_c('span',{staticClass:"paragraph_2"},[(_vm.lag == 'en')?_c('span',[_vm._v("Adr")]):_c('span',[_vm._v("地址")]),_vm._v(" ："+_vm._s(_vm.basedata.adr)),_c('br'),(_vm.lag == 'en')?_c('span',[_vm._v("Tel")]):_c('span',[_vm._v("电话")]),_vm._v(" ："+_vm._s(_vm.basedata.tel)+"    "),_c('br'),(_vm.lag=='en')?_c('span',[_vm._v("Email")]):_c('span',[_vm._v("邮箱")]),_vm._v(" ："+_vm._s(_vm.basedata.email))])])]),_c('div',{staticClass:"line_red"}),_vm._m(2)]):_vm._e(),(_vm.w750 == 1)?_c('div',{staticClass:"group_12 flex-col lianxiwomen"},[_c('div',{staticClass:"box_4 flex-col justify-between"},[_vm._m(3),_c('div',{staticClass:"group_14 flex-col justify-between"},[_vm._m(4),_c('span',{staticClass:"paragraph_2"},[(_vm.lag == 'en')?_c('span',[_vm._v("Adr")]):_c('span',[_vm._v("地址")]),_vm._v(" ："+_vm._s(_vm.basedata.adr)),_c('br'),(_vm.lag == 'en')?_c('span',[_vm._v("Tel")]):_c('span',[_vm._v("电话")]),_vm._v(" ："+_vm._s(_vm.basedata.tel)+"    "),_c('br'),(_vm.lag=='en')?_c('span',[_vm._v("Email")]):_c('span',[_vm._v("邮箱")]),_vm._v(" ："+_vm._s(_vm.basedata.email))]),_vm._m(5)])]),_c('div',{staticClass:"line_red"}),_vm._m(6)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_13 flex-col align-center"},[_c('div',{staticClass:"fw2 flex-col"}),_c('div',{staticClass:"fw flex-col"},[_c('img',{staticClass:"fwf",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/fw2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-red flex-row"},[_c('img',{staticClass:"logo-red-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/logo-red.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_5 flex-row justify-between"},[_c('span',{staticClass:"text_62"},[_vm._v("IPmotion. 沪ICP备2020037860号")]),_c('div',{staticClass:"social-share",attrs:{"data-initialized":"true"}},[_c('a',{staticClass:"social-share-icon icon-wechat",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-weibo",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-qq",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-twitter",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-linkedin",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-facebook",attrs:{"href":"#"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_13 flex-col align-center"},[_c('div',{staticClass:"fw flex-col"},[_c('img',{staticClass:"fwf",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/fw2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-red flex-row"},[_c('img',{staticClass:"logo-red-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/logo-red.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-share",staticStyle:{"transform":"scale(1.3)","margin-left":"24px"},attrs:{"data-initialized":"true"}},[_c('a',{staticClass:"social-share-icon icon-wechat",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-weibo",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-qq",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-twitter",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-linkedin",attrs:{"href":"#"}}),_c('a',{staticClass:"social-share-icon icon-facebook",attrs:{"href":"#"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_5 flex-col justify-between"},[_c('span',{staticClass:"text_62"},[_vm._v("IPmotion. 沪ICP备2020037860号")])])
}]

export { render, staticRenderFns }